/*-----------------------------------------------------------------------------------

    Template Name:TOVO APP
    Template URI: http://themes.pixelstrap.com/tovo
    Description: This is App Landing Page
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------*/
.caption-img {
  filter: hue-rotate(335deg);
}

.loader {
  background-color: #4a4a4a;
}

h1 {
  color: #4a4a4a;
}

.price-type h2 {
  color: #4a4a4a;
}

.home-two .slide-cap-desc {
  color: #f1f1f1;
}

.navbar-light .default-nav .navbar-nav .nav-link.active {
  color: #4a4a4a;
}
.navbar-light.darkHeader .navbar-nav .nav-link.active {
  color: #4a4a4a !important;
}
.navbar-light.darkHeader .navbar-nav .nav-item.dropdown .dropdown-menu .nav-link:hover {
  color: #4a4a4a;
}

.slide-cap-desc {
  color: #f1f1f1;
}

.tap-top {
  background: linear-gradient(to right, #737373, #4a4a4a) !important;
}

.about-right {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%);
}

.address-bar {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%);
}

.team-block .team-social li a {
  border: 1px solid #4a4a4a;
}
.team-block .team-social li a:hover {
  background-color: #4a4a4a;
  border: 1px solid #4a4a4a;
}

.color-picker .settings-header {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}
.color-picker .settings-header h3 {
  color: white;
}
.color-picker .section {
  border: 1px solid #4a4a4a;
}
.color-picker a.handle {
  border: 1px solid #4a4a4a;
  background: #4a4a4a;
}

.navbar-brand img {
  filter: hue-rotate(335deg);
  height: 20px;
}

.home-circle1 img {
  filter: hue-rotate(335deg);
}

.home-circle2 img {
  filter: hue-rotate(335deg);
}

.home-circle3 img {
  filter: hue-rotate(335deg);
}

.home-right img {
  filter: hue-rotate(335deg);
}

.about-phone img {
  filter: hue-rotate(335deg);
}

.feature-phone img {
  filter: hue-rotate(335deg);
}

.about-circle1 img {
  filter: hue-rotate(335deg);
}

.about-circle2 img {
  filter: hue-rotate(335deg);
}

.feature-style .feature-icon img {
  filter: hue-rotate(335deg);
}

.feature-circle1 img {
  filter: hue-rotate(335deg);
}

.feature-circle2 img {
  filter: hue-rotate(335deg);
}

.screenshot-circle1 img {
  filter: hue-rotate(335deg);
}

.screenshot-circle2 img {
  filter: hue-rotate(335deg);
}

.screenshot-circle3 img {
  filter: hue-rotate(335deg);
}

.screenshot-carousel img {
  filter: hue-rotate(335deg);
}

.team-circle1 img {
  filter: hue-rotate(335deg);
}

.team-circle2 img {
  filter: hue-rotate(335deg);
}

.team-circle3 img {
  filter: hue-rotate(335deg);
}

.testi-circle1 img {
  filter: hue-rotate(335deg);
}

.testi-circle2 img {
  filter: hue-rotate(335deg);
}

.testi-circle3 img {
  filter: hue-rotate(335deg);
}

.contact-right img {
  filter: hue-rotate(335deg);
}

.contact-circle1 img {
  filter: hue-rotate(335deg);
}

.contact-circle2 img {
  filter: hue-rotate(335deg);
}

.animated-circle img {
  filter: hue-rotate(335deg);
}

.footer-text img {
  filter: hue-rotate(335deg);
}

.testimonial-text h3 {
  color: #4a4a4a;
}

.btn-custom.theme-color {
  background-image: linear-gradient(to right, #4a4a4a, #737373, #4a4a4a, #4a4a4a);
}
.btn-custom.theme-color:hover {
  background-position: 100% 0;
}

#fp-nav ul li a.active {
  border: 1px solid #4a4a4a;
}
#fp-nav ul li a.active span {
  background: #4a4a4a !important;
}
#fp-nav ul li a span {
  background: #4a4a4a !important;
}
#fp-nav ul li:hover a.active span {
  background: #4a4a4a !important;
}

.fp-slidesNav ul li a span {
  background: #4a4a4a !important;
}
.fp-slidesNav ul li a.active span {
  background: #4a4a4a !important;
}
.fp-slidesNav ul li:hover a.active span {
  background: #4a4a4a !important;
}

.theme-nav .navbar-nav .active > .nav-link {
  color: #4a4a4a;
}

.home-style-two .navbar-nav .dropdown-menu .nav-link:hover {
  color: #4a4a4a !important;
}

.three .navbar-nav .dropdown-menu .nav-link:hover {
  color: #4a4a4a !important;
}

.abt-hover:hover .icon-hover {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%);
}

.icon-hover:hover {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%);
  border: 1px solid #ff8fc0;
}

.team-carousel.owl-theme .owl-dots .owl-dot span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}
.team-carousel.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}

/* .slick-dots li button:before {
    background: linear-gradient(to bottom, #21ecff 0, #2cc6ff 99%) !important; }  
  

.slick-dots li.slick-active button:before {
  background: linear-gradient(to bottom, #21ecff 0, #2cc6ff 99%) !important; } */

.blog-carousel.owl-theme .owl-dots .owl-dot span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}
.blog-carousel.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}

.testimonial-carousel.owl-theme .owl-dots .owl-dot span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}
.testimonial-carousel.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}

.price-carousel.owl-theme .owl-dots .owl-dot span {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 99%) !important;
}

.blog-pagin a.page-link {
  color: #4a4a4a;
  border: 1px solid #eee;
}
.blog-pagin a.page-link:hover {
  background-color: #4a4a4a;
}

.page-item.active .page-link {
  background-color: #4a4a4a;
}

.tags .badge-theme {
  background-color: #4a4a4a;
  color: white;
}

.blog-cat-detail ul li a:hover,
.manual-down {
  color: #4a4a4a;
}

.price-block:hover .mrp h2 {
  color: #4a4a4a;
}

.authentication-form {
  filter: hue-rotate(335deg);
}

.innerpage-decor img {
  filter: hue-rotate(335deg);
}

.faq .card-header[aria-expanded="true"] a {
  color: #4a4a4a;
}

.review-box h5 {
  color: #4a4a4a;
}

@media (max-width: 768px) {
  .home-two,
  .home-three {
    background-color: #4a4a4a;
  }
}

.theme-nav .nav-item:hover .nav-link {
  color: #4a4a4a;
}
.theme-nav .nav-item.dropdown .dropdown-menu .nav-item .nav-link.active {
  color: #4a4a4a !important;
}
.theme-nav .nav-item.dropdown .dropdown-menu .nav-item .nav-link:hover {
  color: #4a4a4a !important;
  transition: all 0.3s ease;
}
.theme-nav.darkHeader .dropdown-menu .nav-item .nav-link.active {
  color: #4a4a4a;
}
.theme-nav .navbar-nav .active > .nav-link {
  color: #4a4a4a;
}

.rounded-dots .slick-dots .slick-active button:before {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 100%);
  background: #4a4a4a;
}

.rounded-dots .slick-dots button:before {
  background: linear-gradient(to bottom, #737373 0, #4a4a4a 100%);
  background: #4a4a4a;
}

.theme-nav .nav-item.dropdown .dropdown-menu .dropdown-item .nav-link:hover {
  color: #4a4a4a !important;
}